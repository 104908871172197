<template>
  <div id="Manage">

    <CommonManageMain>
      <template v-slot:header>
        <el-page-header @back="goBack"
                        style="margin-bottom:100px"
                        content="特权产品"></el-page-header>
        <el-button type="primary"
                   size="small"
                   @click="clickEditData">同步特权规格</el-button>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig">
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getPrivilegeGoodsListNorms, synchroPrivilegeGoods } from 'api/productManage.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增商品',
        editName: '编辑商品',
        isShowDialogVisible: false
      },
      // form表单 基础数据
      openForm: {
        name: '',
        icon: '',
        sort: '',
        status: false,
      },
      openFormLabel: [
        {
          model: 'name',
          label: '特权分类名称'
        },
        {
          model: 'icon',
          label: '图标',
          type: 'upload',
          maxSize: 300,
          imgHeight: '1280',
          imgWidth: '808',
          // required: true
        },
        {
          model: 'sort',
          label: '排序'
        },
        {
          model: 'status',
          label: '状态',
          type: 'switch'
        }
      ],
      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchformLabel: [
        {
          model: 'keyword',
          label: '分类名称',
          labelOFF: true,
          options: []
        }
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: 'ID'
        },
        {
          prop: 'spec_name',
          label: '名称'
        },
        {
          prop: 'official_price',
          label: '官方价格'
        },
        {
          prop: 'settlement_price',
          label: '结算价格'
        },
        {
          prop: 'market_price',
          label: '市场价格'
        },
        {
          prop: 'sales_price',
          label: '销售价格'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'stateNotouch'
        },
        {
          prop: 'update_time',
          label: '更新时间',
        },
      ],
      tableConfig: {
        isShowLoading: false,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示选择多行数据
        selection: true
      },


      privilegeAllDataObj: {},
      searchData: '',//存放搜索的数据，分页时使用
      //被选中特权产品ID
      goods_id: '',
    }
  },
  mounted () {
    this.goods_id = this.$route.query.goods_id//传参 特权产品ID
    this.updataTableData()
  },
  watch: {
    //将字段转换为Elementui能用的字段
    privilegeAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.privilegeAllDataObj,
        {
          status: 'state',
        },
        0
      )
    },
  },

  methods: {
    //   获取--分类列表数据      
    getPrivilegeGoodsListNorms (data) {
      getPrivilegeGoodsListNorms(data).then((res) => {
        console.log(res)
        const DATA = res.data
        this.privilegeAllDataObj = DATA.data
      })
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
    //刷新表格数据
    updataTableData () {
      const DATA = {
        goods_id: this.goods_id
      }
      this.getPrivilegeGoodsListNorms(DATA)
    },
    // 同步规格
    clickEditData (row) {
      console.log(row)
      this.$confirm('确定同步这条记录吗？', '提示', { type: "success" }).then(() => {
        const DATA = {
          goods_id: this.goods_id
        }
        synchroPrivilegeGoods(DATA).then((res) => {
          const DATA = res.data
          console.log(res)
          if (DATA.code == 200) {
            this.$message.success('同步成功！');
          }
          this.updataTableData();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消同步'
        });
      })
    },
    goBack () {
      this.$router.push({ name: 'privilegeGoods' })
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 720px;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .manage-header {
    height: 35px;
  }
}
</style>
